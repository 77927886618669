import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useBrand } from '../hooks/useBrand';

export function useAuth(session: Session | null): boolean {
  const router = useRouter();
  const brand = useBrand();
  const [isValidAuth, setIsValidAuth] = useState(false);

  useEffect(() => {
    if (brand.requiresAuth && !brand.offer && !session) {
      router.push(`/login?callbackUrl=${encodeURIComponent(router.asPath)}`);
    } else if (session && !brand.allowed) {
      router.push(`/login?error=domain&callbackUrl=${encodeURIComponent(router.asPath)}`);
    } else {
      setIsValidAuth(true);
    }
  }, [router, session, brand.requiresAuth, brand.allowed, brand.offer]);

  return !brand.requiresAuth || isValidAuth;
}
