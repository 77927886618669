import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePreferences } from '@/contexts/PreferencesContext';
import { AmountField } from '../../shared/fields/AmountField';
import { IState } from '../Croatia/interfaces/IState';
import { SectionHeader } from '../../shared/SectionHeader';

interface IPorscheLeasingInfoProps {
  state: IState;
  setField(name: string, value: unknown): void;
  companyCostLabel: string;
}

export const PorscheLeasingInfo: FC<IPorscheLeasingInfoProps> = ({ state, setField, companyCostLabel }) => {
  const { t } = useTranslation();
  const {
    preferences: { details },
  } = usePreferences();

  return (
    <>
      <AmountField
        title={t('cars.carLeasingMonthlyNoVat')}
        value={state.values.carLeasingMonthly}
        onChange={(value) => setField('carLeasingMonthly', value)}
        isInvalid={state.values.carLeasingMonthly < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carMonthlyNoVat')}
        value={state.values.carMonthlyNoVat}
        onChange={(value) => setField('carMonthlyNoVat', value)}
        isInvalid={state.values.carMonthlyNoVat < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carMonthlyWithVat')}
        value={state.values.carMonthlyWithVat}
        onChange={(value) => setField('carMonthlyWithVat', value)}
        isInvalid={state.values.carMonthlyWithVat < 0}
        min={0}
      />

      {details === 2 && (
        <>
          <SectionHeader title={t(companyCostLabel)} sub />
          <AmountField title={t('cars.carMonthlyNoVat')} value={state.values.carMonthlyNoVat} />
          <AmountField title={t('cars.carMonthlyCompanyVat')} value={state.calculated.car.monthlyCompanyVat} />
          <AmountField title={t('cars.carPayNarav')} value={state.calculated.car.payNarav} />
          <AmountField title={t('cars.carMonthlyTotal')} value={state.calculated.car.companyTotalMonthly} />
        </>
      )}

      {details === 2 && state.calculated.narav && (
        <>
          <SectionHeader title={t('common.narav.title')} sub />
          <AmountField title={t('common.narav.net')} value={state.calculated.narav.neto} />
          <AmountField title={t('common.narav.gross2')} value={state.calculated.narav.bruto2} />
          <AmountField title={t('common.narav.gross1')} value={state.calculated.narav.bruto1} />
          <AmountField title={t('common.narav.brutoDoprinosi')} value={state.calculated.narav.brutoDoprinosi} />
          <AmountField
            title={t('common.narav.netoDoprinosi')}
            value={state.calculated.narav.bruto1 - state.calculated.narav.dohodak}
          />
          <AmountField title={t('common.narav.porez')} value={state.calculated.narav.porez} />
          <AmountField title={t('common.narav.prirez')} value={state.calculated.narav.prirez} />
          <AmountField title={t('common.narav.tax')} value={state.calculated.narav.bruto2 * 0.2} />
          <AmountField
            title={t('common.narav.cost')}
            value={
              state.calculated.narav.bruto2 * 0.2 +
              state.calculated.narav.brutoDoprinosi +
              state.calculated.narav.porez +
              state.calculated.narav.prirez +
              state.calculated.narav.bruto1 -
              state.calculated.narav.dohodak
            }
          />
        </>
      )}

      {details >= 1 && (
        <>
          <SectionHeader title={t('cars.summary')} sub />
          <AmountField title={t('cars.carNetWithCar')} value={state.calculated.car.netWithCar} />
          <AmountField title={t('cars.carNetDifference')} value={state.calculated.car.netDifference} />
          <AmountField title={t('cars.carCompanyTotalCost')} value={state.calculated.car.companyTotalCost} />
        </>
      )}
    </>
  );
};
