import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePreferences } from '../../../contexts/PreferencesContext';
import { AmountField } from '../../shared/fields/AmountField';
import { SectionHeader } from '../../shared/SectionHeader';
import { IState } from '../Croatia/interfaces/IState';

interface IOtpLeasingInfoProps {
  state: IState;
  setField(name: string, value: unknown): void;
  companyCostLabel: string;
}

export const OtpLeasingInfo: FC<IOtpLeasingInfoProps> = ({ state, setField, companyCostLabel }) => {
  const { t } = useTranslation();
  const {
    preferences: { details },
  } = usePreferences();

  return (
    <>
      <AmountField
        title={t('cars.carLeasingMonthly')}
        value={state.values.carLeasingMonthly}
        onChange={(value) => setField('carLeasingMonthly', value)}
        isInvalid={state.values.carLeasingMonthly < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carMaintenance')}
        value={state.values.carMaintenance}
        onChange={(value) => setField('carMaintenance', value)}
        isInvalid={state.values.carMaintenance < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carOther')}
        value={state.values.carOther}
        onChange={(value) => setField('carOther', value)}
        isInvalid={state.values.carOther < 0}
        min={0}
      />

      {details >= 1 && (
        <AmountField title={t('cars.carLeasingDuration')} value={state.calculated.car.leasingDuration} isPlainNumber />
      )}

      {details === 2 && (
        <>
          <SectionHeader title={t(companyCostLabel)} sub />
          <AmountField title={t('cars.carMonthlyLeasing')} value={state.calculated.car.monthlyLeasing} />
          <AmountField title={t('cars.carMonthlyMaintenance')} value={state.calculated.car.monthlyMaintenance} />
          <AmountField title={t('cars.carMonthlyCompanyVat')} value={state.calculated.car.monthlyCompanyVat} />
          <AmountField title={t('cars.carPayNarav')} value={state.calculated.car.payNarav} />
          <AmountField title={t('cars.carMonthlyTotal')} value={state.calculated.car.companyTotalMonthly} />
        </>
      )}

      {details >= 1 && (
        <>
          <SectionHeader title={t('cars.summary')} sub />
          <AmountField title={t('cars.carNetWithCar')} value={state.calculated.car.netWithCar} />
          <AmountField title={t('cars.carNetDifference')} value={state.calculated.car.netDifference} />
          <AmountField title={t('cars.carCompanyTotalCost')} value={state.calculated.car.companyTotalCost} />
        </>
      )}
    </>
  );
};
