import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePreferences } from '../../../contexts/PreferencesContext';
import { AmountField } from '../../shared/fields/AmountField';
import { SectionHeader } from '../../shared/SectionHeader';
import { IState } from '../Croatia/interfaces/IState';

interface IOtpLeasingProps {
  state: IState;
  setField(name: string, value: unknown): void;
  companyCostLabel: string;
  legacy?: boolean;
}

export const OtpLeasing: FC<IOtpLeasingProps> = ({ state, setField, companyCostLabel, legacy }) => {
  const { t } = useTranslation();
  const {
    preferences: { details },
  } = usePreferences();

  return (
    <>
      <SectionHeader title={t('cars.otpBill')} sub />
      <AmountField
        title={t('cars.carLeasingMonthly')}
        value={state.values.carLeasingMonthly}
        onChange={(value) => setField('carLeasingMonthly', value)}
        isInvalid={state.values.carLeasingMonthly < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carMaintenanceFlota')}
        value={state.values.carMaintenance}
        onChange={(value) => setField('carMaintenance', value)}
        isInvalid={state.values.carMaintenance < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carLeasingVat')}
        value={state.values.carLeasingVat}
        onChange={(value) => setField('carLeasingVat', value)}
        isInvalid={state.values.carLeasingVat < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carSpecialTax')}
        value={state.values.carSpecialTax}
        onChange={(value) => setField('carSpecialTax', value)}
        isInvalid={state.values.carSpecialTax < 0}
        min={0}
      />
      {legacy && (
        <>
          <SectionHeader title={t('cars.otpOnceBill')} sub />
          <AmountField
            title={t('cars.carLeasingOnce')}
            value={state.values.carLeasingOnce}
            onChange={(value) => setField('carLeasingOnce', value)}
            isInvalid={state.values.carLeasingOnce < 0}
            min={0}
          />
          <AmountField
            title={t('cars.carLeasingVatOnce')}
            value={state.values.carLeasingVatOnce}
            onChange={(value) => setField('carLeasingVatOnce', value)}
            isInvalid={state.values.carLeasingVatOnce < 0}
            min={0}
          />
        </>
      )}
      <SectionHeader title={t('cars.czvhBill')} sub />
      <AmountField
        title={t('cars.carRegistration')}
        value={state.values.carRegistration}
        onChange={(value) => setField('carRegistration', value)}
        isInvalid={state.values.carRegistration < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carRegistrationVat')}
        value={state.values.carRegistrationVat}
        onChange={(value) => setField('carRegistrationVat', value)}
        isInvalid={state.values.carRegistrationVat < 0}
        min={0}
      />
      <SectionHeader title={t('cars.insuranceBill')} sub />
      <AmountField
        title={t('cars.carInsurance')}
        value={state.values.carInsurance}
        onChange={(value) => setField('carInsurance', value)}
        isInvalid={state.values.carInsurance < 0}
        min={0}
      />
      <AmountField
        title={t('cars.carKasko')}
        value={state.values.carKasko}
        onChange={(value) => setField('carKasko', value)}
        isInvalid={state.values.carKasko < 0}
        min={0}
      />

      {details >= 1 && (
        <AmountField title={t('cars.carLeasingDuration')} value={state.calculated.car.leasingDuration} isPlainNumber />
      )}

      {details === 2 && (
        <>
          <SectionHeader title={t(companyCostLabel)} sub />
          <AmountField title={t('cars.carPayNarav')} value={state.calculated.car.payNarav} />
          <AmountField title={t('cars.carMonthlyTotal')} value={state.calculated.car.companyTotalMonthly} />
        </>
      )}

      {details === 2 && state.calculated.narav && (
        <>
          <SectionHeader title={t('common.narav.title')} sub />
          <AmountField title={t('common.narav.net')} value={state.calculated.narav.neto} />
          <AmountField title={t('common.narav.gross2')} value={state.calculated.narav.bruto2} />
          <AmountField title={t('common.narav.gross1')} value={state.calculated.narav.bruto1} />
          <AmountField title={t('common.narav.brutoDoprinosi')} value={state.calculated.narav.brutoDoprinosi} />
          <AmountField
            title={t('common.narav.netoDoprinosi')}
            value={state.calculated.narav.bruto1 - state.calculated.narav.dohodak}
          />
          <AmountField title={t('common.narav.porez')} value={state.calculated.narav.porez} />
          <AmountField title={t('common.narav.prirez')} value={state.calculated.narav.prirez} />
          <AmountField title={t('common.narav.tax')} value={state.calculated.narav.bruto2 * 0.2} />
          <AmountField
            title={t('common.narav.cost')}
            value={
              state.calculated.narav.bruto2 * 0.2 +
              state.calculated.narav.brutoDoprinosi +
              state.calculated.narav.porez +
              state.calculated.narav.prirez +
              state.calculated.narav.bruto1 -
              state.calculated.narav.dohodak
            }
          />
        </>
      )}

      {details >= 1 && (
        <>
          <SectionHeader title={t('cars.summary')} sub />
          <AmountField title={t('cars.carNetWithCar')} value={state.calculated.car.netWithCar} />
          <AmountField title={t('cars.carNetDifference')} value={state.calculated.car.netDifference} />
          <AmountField title={t('cars.carCompanyTotalCost')} value={state.calculated.car.companyTotalCost} />
          <AmountField title={t('cars.totalPayIncrease')} value={state.calculated.car.totalPayIncrease} />
        </>
      )}
    </>
  );
};
